@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Open Sans", sans-serif;
    @apply text-sm  text-gray-700;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans Condensed", sans-serif !important;
    @apply  text-gray-700;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


@layer components {

  .height {
    background-color: 100vh;
  }
}

#para, #dates_p, #resto_p {
  white-space: pre-line;
}

/* .kr-embedded .kr-payment-button {
  background-color: #5eaa7b !important;
} */
